// frontend/src/api/face.js
import * as faceapi from 'face-api.js';

// Load models and weights
export async function loadModels() {
  const MODEL_URL = process.env.PUBLIC_URL + '/models';
  await faceapi.loadTinyFaceDetectorModel(MODEL_URL);
  await faceapi.loadFaceLandmarkTinyModel(MODEL_URL);
  await faceapi.loadFaceRecognitionModel(MODEL_URL);
}

export async function getFullFaceDescription(blob, inputSize = 512) {
  // console.log('getFullFaceDescription ========');
  // console.log('blob:', typeof blob); // null
  // console.log('inputSize:', inputSize); // 160

  // tiny_face_detector options
  let scoreThreshold = 0.5;
  const OPTION = new faceapi.TinyFaceDetectorOptions({
    inputSize,
    scoreThreshold
  });
  const useTinyModel = true;

  // fetch image to api
  let img = await faceapi.fetchImage(blob);

  // detect all faces and generate full description from image
  // including landmark and descriptor of each face
  let fullDesc = await faceapi
    .detectAllFaces(img, OPTION)
    .withFaceLandmarks(useTinyModel)
    .withFaceDescriptors();
  // console.log('fullDesc:', fullDesc);
  return fullDesc;
}

const maxDescriptorDistance = 0.5;
export async function createMatcher(faceProfile) {
  console.log('faceProfile:', faceProfile);

  // Create labeled descriptors of member from profile
  // let members = Object.keys(faceProfile);
  // let labeledDescriptors = members.map(
  //   member =>
  //     new faceapi.LabeledFaceDescriptors(
  //       faceProfile[member].name,
  //       faceProfile[member].descriptors.map(
  //         descriptor => new Float32Array(descriptor)
  //       )
  //     )
  // );

  if (!faceProfile || faceProfile.length === 0){
    return null;
  }

  let labeledDescriptors = faceProfile?.map((profile) => 
    new faceapi.LabeledFaceDescriptors(
      profile.name,
      profile.descriptors.map((descriptorArray) =>
        new Float32Array(descriptorArray)
      )
    )
  );
  // let labeledDescriptors = [];
  // for (let i = 0; i < faceProfile.length; i++) {
  //   const profile = faceProfile[i];
  //   console.log('profile:', profile);

  //   let descriptors = [];
  //   for (let j = 0; j < profile.descriptor.length; j++) {
  //     descriptors.push(
  //       new Float32Array(profile.descriptor[j]) // descriptorArray
  //     )
  //   }; // for (j)
  //   labeledDescriptors.push(
  //     new faceapi.LabeledFaceDescriptors(
  //       profile.name,
  //       descriptors
  //     )
  //   )
  // }; // for(i)

  // Create face matcher (maximum descriptor distance is 0.5)
  let faceMatcher = new faceapi.FaceMatcher(
    labeledDescriptors,
    maxDescriptorDistance
  );
  return faceMatcher;
}

