// frontend/src/hooks/GetShareLink.js
import axios from 'axios';

const ussGetShareLink = () => {
  const fastapiUrl = process.env.REACT_APP_FASTAPI_URL;

  const getShareLink = async (fileName) => {
    try {
      console.log('getShareLink ===========================');
      const params = {
        "cloud_path": `/fastapi/${fileName}.jpg`
      }
      // console.log('params:', typeof params, params);
      const response = await axios.get(`${fastapiUrl}/shareLink`, { params });
      console.log('response:', response);
      
      if (response.status === 200) { 
        const linkUrl = response.data.link;
        const imageUrl = response.data.preview;
        return { linkUrl, imageUrl };
      } else {
        return { "Error": response.err.message };
      }

    } catch (error) {
      // console.log('error:', error);
      return { "Error": error.response?.data?.err?.message || error.message };;
    }

  };

  return { getShareLink };
};

export default ussGetShareLink;
