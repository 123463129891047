import React, { useEffect, useState } from 'react';
import useWebSocket, { ReadyState } from 'react-use-websocket';

// const WS_URL = 'ws://183.111.236.249:5000';
// const WS_URL = 'wss://miroslav.cloud249.com/socket';
const WS_URL = process.env.REACT_APP_WEBSOCKET_URL;

function Front2() {
  const [buttonClicked, setButtonClicked] = useState(false);
  const { lastJsonMessage, readyState } = useWebSocket(WS_URL, {
    // onOpen: () => {
    //   console.log('WebSocket connection established.');
    // },
    share: true,
    // filter: () => false,
    // retryOnError: true,
    shouldReconnect: () => true
  });

  useEffect(() => {
    console.log('lastJsonMessage:', lastJsonMessage);
    if (lastJsonMessage && lastJsonMessage.type === 'buttonClick') {
      setButtonClicked(true);
    }

  }, [lastJsonMessage]);


  return (
    <div>
      test
      {buttonClicked && <p>front1 버튼 클릭 이벤트가 수신됨</p>}
    </div>
  );
}

export default Front2;
