// frontend/src/hooks/GetDescriptors.js
import { useState, useEffect } from "react";
import axios from 'axios';

const useGetDescriptors = () => {
  const serverUrl = process.env.REACT_APP_BACKEND_URL;
  // console.log('serverUrl:', serverUrl);

  const [descriptors, setDescriptors] = useState([]);
  
  useEffect(() => {
    const fetchDescriptors  = async () => {
      try {      
        // const storedToken = localStorage.getItem('token');
        const storedToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI3ZmMxNWYxZS1mNGY1LTQyYmYtYjRkOC0yNTk1MjY1ODIxMGYiLCJpYXQiOjE3MDU2MzQzODEsImV4cCI6MTc1MzYzNDM4MX0.bCeBO3vjMWYZBq4xuy8VkjX4SBMwMhINXtYAbYPomCc';
        const headers = {
          headers: {
            'Authorization': `Bearer ${storedToken}`
          }
        };
        const response = await axios.get(`${serverUrl}descriptors`, headers);
        // console.log('fetchDepartments:', response.data)

        if (response.status === 200) {
          setDescriptors(response.data);
        }

      } catch (error) {
        console.error('Error fetching descriptor:', error);
      }
    };

    fetchDescriptors();
  }, [serverUrl]);

  return descriptors;

};

export default useGetDescriptors;
