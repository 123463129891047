// frontend/src/hooks/SaveDescriptors.js
import axios from 'axios';

const useSaveDescriptors = () => {
  const serverUrl = process.env.REACT_APP_BACKEND_URL;

  const saveDescriptor = async (unknownDescriptor) => {
    try {
      // const storedToken = localStorage.getItem('token');
      const storedToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI3ZmMxNWYxZS1mNGY1LTQyYmYtYjRkOC0yNTk1MjY1ODIxMGYiLCJpYXQiOjE3MDU2MzQzODEsImV4cCI6MTc1MzYzNDM4MX0.bCeBO3vjMWYZBq4xuy8VkjX4SBMwMhINXtYAbYPomCc';
      
      if (storedToken) {
        const headers = {
          headers: {
            'Authorization': `Bearer ${storedToken}`
          }
        };
        const descriptorArray = Array.prototype.slice.call(unknownDescriptor.descriptors[0]);
        // const descriptorArray = Array.from(unknownDescriptor.descriptors[0]);
        const data = {
          "name": unknownDescriptor.name,
          "descriptors": [ descriptorArray ]
        };
        // console.log('url:', `${serverUrl}descriptors`);
        // console.log('headers:', headers);
        // console.log('descriptorArray1', descriptorArray);
        // console.log('data:', data);
        // console.log(Array.isArray(data.descriptor), data.descriptor.length); // true, 1
        // console.log(Array.isArray(data.descriptor[0]), data.descriptor[0].length); // true, 1
        // console.log(Array.isArray(data.descriptor[0][0]), data.descriptor[0][0].length); // false, 128
        const response = await axios.post(`${serverUrl}descriptors`, data, headers);
        
        if (response.status === 200) {
          return { success: true };
        } else {
          return { success: false, message: response.err.message };
        }
      }

    } catch (error) {
      return { success: false, message: error.response?.data?.err?.message || error.message };;
    }

  };

  return { saveDescriptor };
};

export default useSaveDescriptors;
