// frontend/src/hooks/UploadPhoto.js
import axios from 'axios';

const useUploadPhoto = () => {
  const serverUrl = process.env.REACT_APP_BACKEND_URL;

  const uploadPhoto = async (file) => {
    try {
      // const storedToken = localStorage.getItem('token');
      const storedToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI3ZmMxNWYxZS1mNGY1LTQyYmYtYjRkOC0yNTk1MjY1ODIxMGYiLCJpYXQiOjE3MDU2MzQzODEsImV4cCI6MTc1MzYzNDM4MX0.bCeBO3vjMWYZBq4xuy8VkjX4SBMwMhINXtYAbYPomCc';
      
      if (storedToken) {
        const headers = {
          headers: {
            'Authorization': `Bearer ${storedToken}`
          }
        };
        const formData = new FormData();
        formData.append("imageFile", file);
        // for (let key of formData.keys()) {
        //   console.log(key, ":", formData.get(key));
        // }
        const response = await axios.post(`${serverUrl}upload`, formData, headers);
        // console.log('response:', response);
        
        if (response.status === 200) {
          console.log('Save Photo in backend server');
          return { success: true };
        } else {
          return { success: false, message: response.err.message };
        }
      }

    } catch (error) {
      // console.log('error:', error);
      return { success: false, message: error.response?.data?.err?.message || error.message };;
    }

  };

  return { uploadPhoto };
};

export default useUploadPhoto;
