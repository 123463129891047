// frontend/src/views/VideoInput.js
// VideoInput.1.js => 함수형 컴포넌트로 변경 / safari 외에 윈도우 브라우저에서도 동작되도록 수정 / 스냅샷 기능 추가
// VideoInput.2.js => 디스크립터 파일(bnk48.json)을 API로 변경,
//                    unknownDescriptor 배열 변경(누적 -> 한 사람 데이터만)
//                    unknownIndex 를 숫자 시퀀스에서 uuid로 변경
//                    캡쳐 버튼 누르면, (1) DB에 디스크립터 저장, (2) 서버에 사진 저장, (3) DB에 메타데이터 저장
// VideoInput.3.js => 입력칸 컴포넌트 추가(TextInput.js)
//                    캡쳐 버튼 누르면, (1) name 입력칸 추가, (2) 서버에 사진 저장
//                                     (3) DB에 메타데이터 우선 저장
//                    name 입력이 완료되면, (4) DB에 메타데이터 name 업데이트
//                                         (5) DB에 디스크립터 저장 (-> 세부로직 3개)
// VideoInput.4.js => 웹소켓 추가
//                    screenshot 변수 외부에 뻼 => handleUploadPhoto 함수 추가 => capture, handleSnapshotClick 함수 수정
// VideoInput.5.js => handleUploadPhoto 함수 수정 (입력파라미터 추가 -> image, fileName)
//                    handleDrawBox 함수 추가 (서버에 detect된 사진 저장하기 위함)
// VideoInput.6.js => 로컬스토리지가 아닌 Nextcloud에 저장, 카톡 noti에 imageUrl 등 추가

import React, { useState, useEffect, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import Webcam from 'react-webcam';
import uuid from "react-uuid";

import { loadModels, getFullFaceDescription, createMatcher } from '../api/face';
import TextInput from '../components/TextInput';

import useGetDescriptors from '../hooks/GetDescriptors';
import useUploadPhoto from '../hooks/UploadPhoto';
import useUploadNextcloud from '../hooks/UploadNextcloud';
import useSaveDescriptors from '../hooks/SaveDescriptors';
import useSaveMetadata from '../hooks/SaveMetadata';
import useUpdateDescriptors from '../hooks/UpdateDescriptors';
import useUpdateMetadata from '../hooks/UpdateMetadata';
import useSendKakaoMe from '../hooks/SendKakaoMe';
import useGetShareLink from '../hooks/GetShareLink';

import useWebSocket, { ReadyState } from 'react-use-websocket';
const WS_URL = process.env.REACT_APP_WEBSOCKET_URL;

// Import face profile
// const JSON_PROFILE = require('../descriptors/bnk48.json');

const WIDTH = 420;
const HEIGHT = 420;
const inputSize = 160;

const VideoInput = () => {
  const webcamRef = useRef(null);
  const canvasRef = useRef(null);
  // const [fullDesc, setFullDesc] = useState(null);
  const [detections, setDetections] = useState(null);
  const [descriptors, setDescriptors] = useState(null);
  const [faceMatcher, setFaceMatcher] = useState(null);
  const [match, setMatch] = useState(null);
  const [facingMode, setFacingMode] = useState(null);
  const [screenshot, setScreenshot] = useState(null);

  const [imageList, setimageList] = useState([]);
  const [unknownDescriptors, setUnknownDescriptors] = useState({});
  let unknownIndex; // uuid
  const [showTextInput, setShowTextInput] = useState(false);
  const [inputName, setInputName] = useState(null);
  // let linkUrl, imageUrl;

  const JSON_PROFILE = useGetDescriptors();
  const names = JSON_PROFILE.map(item => item.name);
  
  const { uploadPhoto } = useUploadPhoto();
  const { uploadNextcloud } = useUploadNextcloud();
  const { saveDescriptor } = useSaveDescriptors();
  const { saveMetadata } = useSaveMetadata();
  const { updateDescriptor } = useUpdateDescriptors();
  const { updateMetadata } = useUpdateMetadata();
  const { sendKakaoMe } = useSendKakaoMe();
  const { getShareLink } = useGetShareLink();

  const { sendJsonMessage, lastJsonMessage, readyState } = useWebSocket(WS_URL, {
    share: true,
    shouldReconnect: () => true
  });

  useEffect(() => {
    console.log('lastJsonMessage:', lastJsonMessage);
    if (lastJsonMessage && lastJsonMessage.type === 'buttonClick') {
      const userName = lastJsonMessage.data.content;
      // console.log('userName:', userName);

      handleButtonClick(userName);
      // handleUploadPhoto("",userName); // fileName = ${userName}_${timestamp}
    }
  }, [lastJsonMessage]);

  useEffect(() => {
    const init = async () => {
      await loadModels();

      const matcher = await createMatcher(JSON_PROFILE);
      setFaceMatcher(matcher);
      setInputDevice();
    };
    if (JSON_PROFILE && JSON_PROFILE.length > 0 ) {
     init();
    }
  }, [JSON_PROFILE]);

  const setInputDevice = async () => {
    const devices = await navigator.mediaDevices.enumerateDevices();
    // console.log('devices:', devices);
    // const inputDevice = devices.filter(device => device.kind === 'videoinput');
    const inputDevice = devices.filter(device => device.kind === 'videoinput' && device.label !== "OBS Virtual Camera");
    setFacingMode(inputDevice.length < 2 ? 'user' : { exact: 'environment' })
  };

  useEffect(() => {
    const interval = setInterval(() => {
      capture();
    }, 1500);
    return() => clearInterval(interval);
  }, [facingMode]);

  const capture = async () => {
    if (webcamRef.current) {
      const newScreenshot = webcamRef.current.getScreenshot();
      // Create face description
      if (newScreenshot !== null) {
        const fullDesc = await getFullFaceDescription(newScreenshot, inputSize);
        if (fullDesc) {
          setDetections(fullDesc.map(fd => fd.detection));
          setDescriptors(fullDesc.map(fd => fd.descriptor));
          setScreenshot(newScreenshot);
          // console.log('detections1:', detections); // null
        }
  
        // console.log('descriptors2:', descriptors); // null
        // if (descriptors && faceMatcher) {
        //   const matches = await descriptors.map(descriptor => faceMatcher.findBestMatch(descriptor));
        //   console.log('matches:', matches);
        //   setMatch(matches);
        // }
      }
    }
  }; // capture

  useEffect(() => {
    // console.log('descriptors3:', descriptors);
    if (descriptors && faceMatcher) {
      const matches = descriptors.map(descriptor => faceMatcher.findBestMatch(descriptor));
      setMatch(matches);
    }
  }, [detections, descriptors])

  const handleSnapshotClick = async () => {
    console.log('handleSnapshotClick ========');

    if (screenshot) {
      unknownIndex = uuid();
      const matches = descriptors.map(descriptor => faceMatcher.findBestMatch(descriptor));
      
      if (matches.length > 0) {
        const label = matches[0]._label;
        console.log('label:', label);

        if (label === 'unknown') {
          setShowTextInput(true); // TextInput 나타내기

          const newUnknownDescriptors = {
            uuid: unknownIndex,
            descriptors: descriptors,
          };

          setUnknownDescriptors(newUnknownDescriptors);
          console.log('unknownDescriptors1:', newUnknownDescriptors);
          
          // Save Unknown Photo.
          // handleUploadPhoto(screenshot, unknownIndex);
          handleUploadNextcloud(screenshot, unknownIndex);
          
        }
        
        // Create snapshot
        const randomId = unknownIndex;
        setimageList((previousData) => {
          return [{ id: randomId, imageName: screenshot }, ...previousData];
        });

      }
    }
  }


  /*
  If a descriptor exists, Save the Photo in Backend Server.
  */
  const handleUploadPhoto = (image, fileName) => {
    if (image) { // base64 image
      // Save Photo in backend server
      const byteCharacters = atob(image.split(",")[1]);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteNumbers.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: "image/jpeg"});
      const file = new File([blob], `${fileName}.jpg`);
      uploadPhoto(file);
    } else {
      console.log('No descriptor, no one detected.') // if (fullDesc)
    }
  };

  /*
  If a descriptor exists, Save the Photo to Nextcloud.
  */
  const handleUploadNextcloud = async (image, fileName) => {
    if (image) { // base64 image
      // Save Photo to Nextcloud
      const resUpload = uploadNextcloud(image, fileName);
      return resUpload
    } else {
      console.log('No descriptor, no one detected.') // if (fullDesc)
    }
  };

  useEffect(() => {
    // console.log("unknownDescriptors2:", unknownDescriptors);
    if (unknownDescriptors.descriptors) {
      // saveDescriptor(unknownDescriptors); // Save Unknonwn Descriptor in database
      saveMetadata(unknownDescriptors); // Save Unknonwn Metadata in database
    }
  }, [unknownDescriptors])


  const handleNameInputChange = (newName) => {
    setInputName(newName);
  };

  const handleNameInputDone = async () => {
    setShowTextInput(false); // TextInput 감추기
    
    if (inputName) {
      // console.log('inputName:', inputName);
      // console.log('unknownDescriptors:', unknownDescriptors)
      // console.log('JSON_PROFILE:', JSON_PROFILE); // [{name: .., descriptors: ...,}, {name: .., descriptors: ...,}, ...]
      // console.log(typeof JSON_PROFILE, Array.isArray(JSON_PROFILE), JSON_PROFILE.length);
      // console.log('names:', names);
      
      const updateData = {
        uuid: unknownDescriptors.uuid,
        name: inputName,
        descriptors: unknownDescriptors.descriptors
      };
      // console.log('updateData:', updateData)
      const updateMetaRes = await updateMetadata(updateData);

      if (updateMetaRes.success) {
        if (names.includes(inputName)) {
          // console.log('update descriptors!!!!');
          updateDescriptor(updateData);
        } else {
          // console.log('save descriptors!!!!');
          saveDescriptor(updateData);
        }
      } else {
        alert('Failed update metadata. So metadaata name field is null.');
      }

    };
  };

  const handleButtonClick = async (userName) => {
    console.log("handleButtonClick =============");
    if (match && match.length > 0 && readyState === ReadyState.OPEN) {
      // console.log('match:', match.length, match);

      const detectedPersonLabel = match[0]._label;
      const processedImage = await handleDrawBox(detectedPersonLabel);

      if (processedImage) {
        // handleUploadPhoto(processedImage, userName);
        const resUpload = await handleUploadNextcloud(processedImage, userName);
        console.log('resUpload:', resUpload);

        if (resUpload.success) {
          const { linkUrl, imageUrl } = await getShareLink(userName);

          if (detectedPersonLabel !== userName) {
            console.log(`different person!!!! userName: ${userName}, detectedPerson: ${detectedPersonLabel}`);
            const data = {
              "content": userName,
              "linkUrl": linkUrl, // linkUrl,
              "imageUrl": imageUrl // imageUrl
            };
            sendKakaoMe(data);
          }

        }

      }

    } else {
      console.log('No match, or websocket was disconnected...')
      console.log('match:', match);
      console.log('readyState:', readyState);
    }
  };

  const handleDrawBox = async (label) => {
    console.log('handleDrawBox ===========================');
    return new Promise((resolve, reject) => {
      if (screenshot && canvasRef.current) {
        // console.log('detections:', detections);
        console.log('label:', label, typeof label);

        const image = new Image();
        image.src = screenshot;
        image.onload = () => {
          const canvas = canvasRef.current;
          const ctx = canvas.getContext('2d');
          ctx.drawImage(image, 0, 0, canvas.width, canvas.height);

          detections.forEach((detection, i) => {
            const { x, y, width, height } = detection.box;
            ctx.strokeStyle = 'blue';
            ctx.lineWidth = 2;
            ctx.strokeRect(x, y, width, height);

            ctx.font = '25px Arial'; // 글자 스타일은 추후 개선
            ctx.fillStyle = 'blue';
            ctx.fillText(label, x, y);
          });

          resolve(canvas.toDataURL('image/jpeg'));
        }; // onload

        image.onerror = reject;
      } else {
        reject(new Error('No screenshot or canvas available'))
      }
    });
  };



  // render() {
    let videoConstraints = null;
    let camera = '';
    if (!!facingMode) {
      videoConstraints = {
        width: WIDTH,
        height: HEIGHT,
        facingMode: facingMode
      };
      if (facingMode === 'user') {
        camera = 'Front';
      } else {
        camera = 'Back';
      }

      // console.log('videoConstraints:', videoConstraints);
    }

    let drawBox = null;
    if (detections) {
      drawBox = detections.map((detection, i) => {
        let _H = detection.box.height;
        let _W = detection.box.width;
        let _X = detection.box._x;
        let _Y = detection.box._y;
        return (
          <div key={i}>
            <div
              style={{
                position: 'absolute',
                border: 'solid',
                borderColor: 'blue',
                height: _H,
                width: _W,
                transform: `translate(${_X}px,${_Y}px)`
              }}
            >
              {!!match && !!match[i] ? (
                <p
                  style={{
                    backgroundColor: 'blue',
                    border: 'solid',
                    borderColor: 'blue',
                    width: _W,
                    marginTop: 0,
                    color: '#fff',
                    transform: `translate(-3px,${_H}px)`
                  }}
                >
                  {match[i]._label}
                </p>
              ) : null}
            </div>
          </div>
        );
      });
    } 
    
    return (
      <div
        className="Camera"
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >
        {/* <button onClick={toggleWebcam}>
          {isWebcamActive ? 'Turn Off Webcam' : 'Turn On Webcam'}
        </button> */}
        <p>Camera: {camera}</p>
        <div
          style={{
            width: WIDTH,
            height: HEIGHT
          }}
        >
          <div style={{ position: 'relative', width: WIDTH }}>
            {!!videoConstraints ? (
              <div style={{ position: 'absolute' }}>
                {/* {isWebcamActive && ( */}
                <Webcam
                  audio={false}
                  width={WIDTH}
                  height={HEIGHT}
                  ref={webcamRef}
                  screenshotFormat="image/jpeg"
                  videoConstraints={videoConstraints}
                />

                <canvas 
                  ref={canvasRef}
                  style={{ display: 'none' }}
                  width={WIDTH}
                  height={HEIGHT}
                />
                {/* )} */}
              </div>
            ) : null}
            {!!drawBox ? drawBox : null}
          </div>
        </div>

        <div >
          {showTextInput && (
            <div>
              <TextInput onTextChange={handleNameInputChange} />
              <button onClick={handleNameInputDone}>
                Done
              </button>
            </div>
          )}

          <button onClick={handleSnapshotClick}>
            Capture Image
          </button>

          {imageList.map((imageData) => (
            <div key={imageData.id} style={{ display: "inline-block", marginBottom: "30px" }}>
              <img src={imageData.imageName} alt="" /> <br />
              <a href={imageData.imageName} download>
                <i className="fa fa-download"> </i>
                Download
              </a>
            </div>
          ))}
        </div>
      </div>
    );
    
  // }
}

export default withRouter(VideoInput);
