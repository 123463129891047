import React, { useState } from 'react';

const TextInput = ({ onTextChange }) => {
  const [text, setText] = useState('');

  const handleTextChange = (e) => {
    const newText = e.target.value;
    setText(newText);

    onTextChange(newText); // 부모 컴포넌트로 변경된 이벤트 전달
  }

  return (
    <div>
      <label htmlFor="textInput">name:</label>
      <input 
        type="text"
        id="textInput"
        value={text}
        onChange={handleTextChange}
        placeholder="Enter your name"
      />
    </div>
  )
};

export default TextInput;
