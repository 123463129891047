// frontend/src/hooks/SaveDescriptors.js
import axios from 'axios';

const useUpdateDescriptors = () => {
  const serverUrl = process.env.REACT_APP_BACKEND_URL;

  const updateDescriptor = async (unknownDescriptor) => {
    // console.log('updateDescriptor ....');
    // console.log('unknownDescriptor:', unknownDescriptor);
    try {
      // const storedToken = localStorage.getItem('token');
      const storedToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI3ZmMxNWYxZS1mNGY1LTQyYmYtYjRkOC0yNTk1MjY1ODIxMGYiLCJpYXQiOjE3MDU2MzQzODEsImV4cCI6MTc1MzYzNDM4MX0.bCeBO3vjMWYZBq4xuy8VkjX4SBMwMhINXtYAbYPomCc';
      
      if (storedToken) {
        const headers = {
          headers: {
            'Authorization': `Bearer ${storedToken}`
          }
        };
        const descriptorArray = Array.prototype.slice.call(unknownDescriptor.descriptors[0]);
        // const descriptorArray = Array.from(unknownDescriptor.descriptors[0]);
        const data = {
          "name": unknownDescriptor.name,
          "descriptors": [ descriptorArray ]
        };
        // console.log('data:', data);
        const response = await axios.patch(`${serverUrl}descriptors`, data, headers);
        // console.log('response:', response);
        if (response.status === 200) {
          return { success: true };
        } else {
          return { success: false, message: response.err.message };
        }
      }

    } catch (error) {
      return { success: false, message: error.response?.data?.err?.message || error.message };;
    }

  };

  return { updateDescriptor };
};

export default useUpdateDescriptors;
