// frontend/src/hooks/UploadNextcloud.js
import axios from 'axios';

const useUploadNextcloud = () => {
  const fastapiUrl = process.env.REACT_APP_FASTAPI_URL;

  const uploadNextcloud = async (image, fileName) => {
    try {
      console.log('uploadNextcloud ===========================');
      const base64Data = image.split(",")[1]
      const data = {
        "file_name": fileName,
        "data": base64Data
      }
      const response = await axios.post(`${fastapiUrl}/upload`, data);
      console.log('response:', response);
      
      if (response.status === 200) {      
        console.log('Save Photo to Nextcloud');
        return { success: true };
      } else {
        return { success: false, message: response.err.message };
      }

    } catch (error) {
      // console.log('error:', error);
      return { success: false, message: error.response?.data?.err?.message || error.message };;
    }

  };

  return { uploadNextcloud };
};

export default useUploadNextcloud;
