// frontend/src/hooks/SendKakaoMe.js.js
import axios from 'axios';

const useSendKakaoMe = () => {
  const fastapiUrl = process.env.REACT_APP_FASTAPI_URL;

  const sendKakaoMe = async (data) => {
    // console.log('data:', data);
    try {
      console.log('sendKakaoMe ===========================');
      const body = {
        "content": `${data.content}(이)가 아닙니다.`,
        "date": new Date(), // 날짜 포맷 변경
        "link_url": data.linkUrl,
        "image_url": data.imageUrl
      };
      console.log('body:', body);
      const response = await axios.post(`${fastapiUrl}/send/kakao/me`, body);
      // console.log('res:', response);

      if (response.status === 200) {
        return { success: true };
      } else {
        return { success: false, message: response.err.message };
      }


    } catch (error) {
      return { success: false, message: error.response?.data?.err?.message || error.message };;
    }

  };

  return { sendKakaoMe };
};

export default useSendKakaoMe;
